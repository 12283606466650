import React, { useState, useEffect } from "react";
import { BiCopyAlt } from "react-icons/bi";
import { FaEnvelopeOpenText } from "react-icons/fa";

import { getSmsHistoryApi } from "../../../../redux/slice/customers/customers.api";
import Loading from "../../../../components/elements/Loading/Loading";

const SmsHistory = ({ customer }: { customer: any }) => {
    const [history, setHistory] = useState<any>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);

    const getHistory = async () => {
        const params = {
            phoneNumber: customer.phone_number,
            search: "password",
        };
        // const headers = {
        //     communityid: '62eecbaf58381fb28d5fa3a0',
        // };
        try {
            setLoading(true);
            const res = await getSmsHistoryApi(params);
            setHistory(res.data);
        } catch (err) {
            //
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => alert("Text copied to clipboard"))
            .catch(err => alert("Could not copy text"));
    };

    useEffect(() => {
        getHistory();
    }, []);

    return (
        <div className="container mx-auto space-y-4 h-max bg-gray-50 flex flex-col p-2">
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-4 justify-end overflow-y-auto">
                    {history && history.length > 0 ? (
                        history.map((hist: any, index: any) => (
                            <li
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className="flex flex-col gap-1 p-2 rounded-lg border border-blue-200 bg-blue-100 "
                            >
                                <p className="font-medium border-b border-black/5">
                                    {hist.body}
                                </p>
                                <button
                                    type="button"
                                    onClick={() => copyToClipboard(hist.body)}
                                    className="flex items-center gap-1 ml-auto cursor-pointer p-1 text-xs w-fit bg-blue-300 hover:bg-blue-400 text-blue-900 font-medium rounded border border-inherit"
                                >
                                    <BiCopyAlt className="text-blue-700" /> Copy
                                    to clipboard
                                </button>
                            </li>
                        ))
                    ) : (
                        <section className="flex flex-col items-center bg-blue-50 text-blue-500 border border-blue-100 rounded py-24">
                            <FaEnvelopeOpenText className="w-6 h-6" />
                            <p className="font-semibold">
                                No SMS Password/Pin Yet...
                            </p>
                        </section>
                    )}
                </div>
            )}
        </div>
    );
};

export default SmsHistory;
