import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import { Button } from "rsuite";
import PageHelmet from "../../../components/blocks/PageHelmet";
import TableSubHeader from "../../../components/blocks/TableSubHeader";
import { CustomButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import { getAllStaffApi } from "../../../redux/slice/staff/staff.api";
import formatDate from "../../../utils/formatDate";
import AddStaff from "./AddStaff";
import FilterStaff from "./FilterStaff";
import StaffDetail from "./StaffDetail";
import PaginationFilter from "../../../components/elements/paginationFilter";

const columns = [
    {
        name: "Fullname",
        selector: (row: any) =>
            `${row.userRecord.first_name} ${row.userRecord.last_name}`,
        grow: 1,
        sortable: true,
    },
    {
        name: "Operations Staff Level",
        selector: (row: any) => row.operationsStaffLevel,
    },
    {
        name: "Works In Customer Support",
        cell: (row: any) => (row.worksInCs ? <div>Yes</div> : <div>No</div>),
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.userRecord.created_at),
    },
];

const Staff = () => {
    const [staff, setStaff] = useState([]);
    const [filteredStaffs, setFilteredStaffs] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [queryObject, setQueryObject] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pagination, setPagination] = useState({
        perPage: 15,
        page: 1,
    });

    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(8);

    const fetchStaff = async (
        pageLimit?: number,
        // pageNumber?: number,
        query?: any
    ) => {
        setLoading(true);
        try {
            const res = await getAllStaffApi(
                pageLimit || pagination.perPage,
                pagination.page,
                query
            );
            // console.log("staff", res);
            setStaff(res.staffsResultSet);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            return null;
        }
    };

    // const onNext = async () => {
    //     const newPage = pagination.perPage + 5;
    //     await fetchStaff(newPage, queryObject);
    //     setPagination({ ...pagination, perPage: newPage });
    // };

    const handleFilterChoice = () => {
        setShowFilter(true);
    };

    const handleShowCreate = () => {
        setShowCreate(true);
    };

    const handleCloseCreate = () => {
        setShowCreate(false);
        fetchStaff();
    };

    const handleRefresh = () => {
        fetchStaff();
    };

    const handleShowDetail = (row: any) => {
        const teamLead = staff.filter(
            (s: any) => s.userId === row?.team?.teamLeadStaffUserId
        );

        setSelectedStaff({
            id: row.userId,
            operationsStaffLevel: row.operationsStaffLevel,
            first_name: row.userRecord.first_name,
            last_name: row.userRecord.last_name,
            phone_number: row.userRecord.phone_number,
            bvn: row.userRecord.bvn,
            nin: row.userRecord.nin,
            gender: row.gender !== null ? row.userRecord.gender : "-",
            worksInCs: row.worksInCs ? "Yes" : "No",
            teamId: row.team !== null && row?.team["_id"],
            teamName: row.team !== null ? row?.team?.teamName : "-",
            teamDescription:
                row.team !== null ? row?.team?.teamDescription : "-",
            teamLead:
                row.team !== null
                    ? `${teamLead[0]["userRecord"]["first_name"]} ${teamLead[0]["userRecord"]["last_name"]}`
                    : "-",
        });
        setShowDetail(true);
    };

    const handleHideDetail = () => {
        setShowDetail(false);
    };

    const filterStaff = (query: any) => {
        setShowFilter(false);

        if (Object.entries(query).length === 0) {
            fetchStaff(pagination.perPage);
            setQueryObject({});
        } else {
            const res = Object.entries(query).filter(e => e[1] !== "");
            fetchStaff(pagination.perPage, Object.fromEntries(res));
            setQueryObject(Object.fromEntries(res));
        }
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return staff.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredStaffs(pageData);
    };

    function filterDataByDateRange(data: any, startDate: any, endDate: any) {
        return data.filter(
            (item: any) =>
                new Date(
                    item.userRecord.created_at.toString().split("T")[0]
                ).getTime() >= new Date(startDate).getTime() &&
                new Date(
                    item.userRecord.created_at.toString().split("T")[0]
                ).getTime() <= new Date(endDate).getTime()
        );
    }

    const handleDateFilter = async (start: any, end: any) => {
        await fetchStaff().then(res => {
            const data = filterDataByDateRange(res.staffsResultSet, start, end);
            setStaff(data);
        });

        // Reset Pagination
        setCurrentPage(1);
        handlePagination(currentPage);
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    useEffect(() => {
        handlePagination(currentPage);
    }, [staff]);

    useEffect(() => {
        fetchStaff();
    }, []);

    return (
        <>
            <PageHelmet title="Staff" />
            <div className="flex items-center justify-between w-full p-0 mb-8">
                <h1 className="text-base font-bold sm:text-lg">
                    Staff Management
                </h1>
                <div>
                    <CustomButton
                        className="bg-[#006399] py-1.5 px-4 text-white hover:bg-[#0073b3] rounded hover:text-white border-0 text-xs sm:text-sm"
                        onClick={handleShowCreate}
                    >
                        Create Staff
                    </CustomButton>
                </div>
            </div>
            <div className="w-full min-h-[50vh] overflow-y-auto">
                <DataTable
                    columns={columns}
                    data={filteredStaffs}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    subHeader
                    subHeaderComponent={
                        <TableSubHeader
                            handleDateChange={handleDateFilter}
                            handleFilter={handleFilterChoice}
                            handleRefresh={handleRefresh}
                            queryObject={queryObject}
                            filterTransaction={filterStaff}
                        />
                    }
                    subHeaderWrap
                    progressPending={loading}
                    progressComponent={<Loading />}
                    onRowClicked={handleShowDetail}
                />
            </div>
            <div className="flex justify-end">
                <PaginationFilter
                    currentPage={currentPage}
                    totalPages={Math.ceil(staff.length / maxRow)}
                    onPageChange={handlePagination}
                />
            </div>
            {/* {Object.entries(staff).length > 15 && (
                <div className="flex justify-end mt-4 text-sm text-white">
                    <CustomButton
                        className="px-6 py-2 mx-2 bg-green-600 rounded-md"
                        onClick={onNext}
                    >
                        Load More
                    </CustomButton>
                </div>
            )} */}

            {showFilter && (
                <FilterStaff
                    open
                    handleClose={() => setShowFilter(false)}
                    handleFilter={filterStaff}
                />
            )}
            {showCreate && (
                <AddStaff
                    open
                    handleClose={() => handleCloseCreate()}
                    staff={staff}
                />
            )}
            {showDetail && (
                <StaffDetail
                    open
                    toggleModal={() => handleHideDetail()}
                    staff={selectedStaff}
                />
            )}
        </>
    );
};

export default Staff;
