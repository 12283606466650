import { validateAll, validations } from "indicative/validator";
import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import { autoDebitCustomerApi } from "../../../redux/slice/customers/customers.api";
import { InfoToast, SuccessToast } from "../../../components/blocks/Alert";

type Props = {
    open: boolean;
    handleClose: () => void;
    id: string;
};

const ActivateAutoDebit = ({ open, handleClose, id }: Props) => {
    const [formData, setFormData] = useState({
        amount: "",
    });
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        const payload = {
            lolaCustomerUserId: id,
            newStatus: true,
            amount: data.amount,
        };

        try {
            const res = await autoDebitCustomerApi(payload);
            if (res.successful) {
                SuccessToast(
                    "Auto-debit enabled! Please click refresh to get updated data."
                );
                handleClose();
            }

            if (!res.successful) {
                InfoToast("Error in activating Auto-debit");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            amount: [
                validations.required(),
                validations.number(),
                validations.above([99]),
            ],
        };

        const messages = {
            "amount.required": "Amount is required",
            "amount.above": "Minimum amount is ₦100",
            "amount.number": "Amount must be a number",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Enable Auto-debit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="pb-1">
                        <FloatingLabelInput
                            type="number"
                            label="Amount"
                            onChange={e => handleInputChange(e)}
                            name="amount"
                            value={formData.amount}
                            error={error.amount}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <DrawerButton onClick={handleSubmit}>
                            {loading ? (
                                <Loading size="sm" />
                            ) : (
                                "Activate Auto Debit"
                            )}
                        </DrawerButton>
                        <Button
                            onClick={handleClose}
                            className="text-red-600 bg-slate-100 hover:text-red-600"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ActivateAutoDebit;
