import axios from "axios";
import store from "store";
import { InfoToast } from "../components/elements/Alerts";
// import { getCookie } from "../utils/cookiesHandler";

// const token = getCookie("act");
// const userId = getCookie("userId");
// const communityId = getCookie("communityId");

// const isDev = process.env.NODE_ENV === "development";
const isProduction = process.env.NODE_ENV === "production";

const walletUrl = isProduction
    ? process.env.REACT_APP_WALLET_URL
    : "http://localhost:3001/wallet_api";

const instance = axios.create({
    //   "proxy": "https://lola-ops-api.herokuapp.com"
    withCredentials: true,
    baseURL: "https://lola-ops-api.lolafinance.com",
});

const walletInstance = axios.create({
    baseURL: walletUrl,
    withCredentials: true,
});

instance.interceptors.request.use(
    (config: any) => {
        const token = store.get("accessToken");
        const userId = store.get("userId");

        if (typeof token !== "undefined" && typeof userId !== "undefined") {
            config.headers["Content-Type"] = "application/json";
            config.headers["accessToken"] = token;
            config.headers["userId"] = userId;
        } else {
            config.headers["Content-Type"] = "application/json";
        }

        return config;
    },
    error => Promise.reject(error)
);

// walletInstance.interceptors.request.use(
//     (config: any) => {
//         store.set("communityId", communityId);
//         config.headers["Content-Type"] = "application/json";

//         return config;
//     },
//     (error: any) => Promise.reject(error)
// );

walletInstance.interceptors.request.use(
    (config: any) => {
        config.headers["Content-Type"] = "application/json";
        // if (isDev) {
        //     config.headers["isLocalhost"] = "true";
        // }
        return config;
    },
    (error: any) => Promise.reject(error)
);

const successHandler = (response: any) => {
    if (
        !response.data.successful &&
        response.data.message === "Your session has expired, Please login."
    ) {
        InfoToast("Your session has expired! Kindly Login again");
        store.clearAll();
        window.location.replace("/signin");
    }
    return response;
};

const errorHandler = (error: any) => Promise.reject(error);

instance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
);

walletInstance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
);

export default instance;

export { walletInstance };
