import React, { useState } from "react";
import { validateAll } from "indicative/validator";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import { DbAllTerminals } from "../../../types/api.types";
import { DrawerButton } from "../../../components/elements/Button";
import { handleValidationError } from "../../../utils/formHandlers";
import { ReAssignTerminalApi } from "../../../redux/slice/posterminals/posterminals.api";
import Loading from "../../../components/elements/Loading/Loading";
import { ErrorToast } from "../../../components/blocks/Alert";

const getDesc = (isAssigned: boolean) => {
    if (isAssigned) return "Reassign terminal to new communities/entities";

    return "First time assignment of terminals requires terminals to be assigned to communities before reassigning to individuals";
};

type Props = {
    handleClose(): void;
    row: DbAllTerminals;
};

const fields = [
    { name: "accountReference", label: "Account Reference (entity reference)" },
    { name: "initialAccountReference", label: "Initial Account Reference" },
] as const;

const ReassignTerminals = (props: Props) => {
    const { handleClose, row } = props;

    const { isAssigned } = row;

    const [formData, setFormData] = useState({
        initialAccountReference: "",
        accountReference: "",
    });
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);

    const handleTerminalReassignment = async () => {
        setLoading(true);
        try {
            const res = await ReAssignTerminalApi({
                ...formData,
                terminalId: row.terminalId,
                assign: true,
                type: "full",
            });

            console.log(res);
        } catch (error) {
            ErrorToast(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            initialAccountReference: "required",
            accountReference: "required",
        };

        const message = {
            "accountReference.required": "Account reference cannot be blank",
            "initialAccountReference.required":
                "Initial account reference cannot be blank",
        };

        validateAll(formData, rules, message)
            .then(() => {
                setErrors({});
                handleTerminalReassignment();
            })
            .catch(err => {
                const formattedErr = handleValidationError(err);
                setErrors(formattedErr);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        setFormData(prev => ({ ...prev, [name]: value }));
    };

    return (
        <DrawerPanel
            title={isAssigned ? "Re-assign terminal" : "Assign Terminal"}
            description={getDesc(isAssigned)}
            open
            handleClose={handleClose}
        >
            <form onSubmit={handleSubmit}>
                {fields.map(({ label, name }) => (
                    <FloatingLabelInput
                        key={name}
                        name={name}
                        label={label}
                        value={formData[name]}
                        onChange={handleChange}
                        error={errors[name]}
                    />
                ))}
                <DrawerButton type="submit">
                    {loading ? <Loading size="xs" /> : "Submit"}
                </DrawerButton>
            </form>
        </DrawerPanel>
    );
};

export default ReassignTerminals;
