import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { InfoToast } from "../../../components/elements/Alerts";
import { CustomButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import { getCommunitySuccessApi } from "../../../redux/slice/reports/reports.api";
import formatDate from "../../../utils/formatDate";
import CommunityReportDetail from "./CommunityReportDetail";

const columns = [
    {
        name: "Staff Phone Number",
        selector: (row: any) => row["Staf Phone Number"],
    },
    { name: "Staff Name", selector: (row: any) => row["Staff Name"] },
    {
        name: "No. of Unique Customer's Called",
        selector: (row: any) => row["Total Number Of Unique Customes Called"],
    },
];

const singlefiedColumn = [
    {
        name: "Customer Called",
        selector: (row: any) => row["customerNameCalled"],
    },
    {
        name: "Customer Phone",
        selector: (row: any) => row["customerPhoneNumber"],
    },
    {
        name: "Followup Date",
        selector: (row: any) => formatDate(row.followUpDate),
    },
    {
        name: "Call Started At",
        selector: (row: any) => formatDate(row.callStartedAt),
    },
    {
        name: "Call Ended At",
        selector: (row: any) => formatDate(row.callEndedAt),
    },
    {
        name: "Call Duration (mins)",
        selector: (row: any) => row["numberOfMinutesCallLasted"],
    },
    {
        name: "Feedback from Call",
        selector: (row: any) => row["feedbackNoteLeftOnTheCustomer"],
    },
];

const CommunityReports = () => {
    const [dateOptions, setDateOptions] = useState<any>({
        fromDate: new Date().toJSON().slice(0, 16),
        toDate: new Date().toJSON().slice(0, 16),
    });
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [singlefiedResult, setSinglefiedResult] = useState([]);
    const [performance, setPerformance] = useState([]);
    const [showDetail, setShowDetail] = useState(false);

    const handleInputChange = (e: any) => {
        const today = new Date().toISOString().slice(0, 10);
        const { name, value } = e.target;

        if (value === "") {
            return setDateOptions({
                ...dateOptions,
                [name]: today,
            });
        }

        return setDateOptions({
            ...dateOptions,
            [name]: value,
        });
    };

    const handleShowDetail = (row: any) => {
        setPerformance(row["Performance Breakdown"]);
        setShowDetail(true);
    };

    const handleCloseDetail = () => {
        setShowDetail(false);
    };

    const fetchReport = async (data?: any) => {
        setLoading(true);
        try {
            const res = await getCommunitySuccessApi(data);
            if (res.successful) {
                setResults(res.resultSet);
                setSinglefiedResult(res.singlefiedList);
            }
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleFilter = () => {
        fetchReport(dateOptions);
    };

    useEffect(() => {
        fetchReport();
    }, []);

    return (
        <>
            <div className="flex flex-col mb-10 sm:space-x-4 sm:flex-row">
                <div className="w-full sm:w-4/12">
                    <FloatingLabelInput
                        type="datetime-local"
                        label="From (Start Date)"
                        name="fromDate"
                        value={dateOptions.fromDate}
                        min="2022-07-18T00:00"
                        max={new Date().toJSON().slice(0, 16)}
                        onChange={e => handleInputChange(e)}
                    />
                </div>
                <div className="w-full sm:w-4/12">
                    <FloatingLabelInput
                        type="datetime-local"
                        label="To (End Date)"
                        name="toDate"
                        value={dateOptions.toDate}
                        min="2022-07-18T00:00"
                        max={new Date().toJSON().slice(0, 16)}
                        onChange={e => handleInputChange(e)}
                    />
                </div>
                <CustomButton
                    buttonStyle="px-6 py-2 h-fit bg-blue-400 hover:bg-blue-500 sm:self-center text-white font-semibold"
                    onClick={handleFilter}
                >
                    Filter
                </CustomButton>
            </div>
            {loading ? (
                <div className="text-center">
                    <Loading size="lg" />
                </div>
            ) : (
                <>
                    <div>
                        <h2 className="text-[#F75500] text-base font-semibold">
                            Staff Report
                        </h2>

                        <DataTable
                            columns={columns}
                            data={results}
                            onRowClicked={handleShowDetail}
                        />
                    </div>
                    <div className="mt-4">
                        <h2 className="text-[#F75500] text-base font-semibold">
                            Customer Report
                        </h2>
                        <DataTable
                            columns={singlefiedColumn}
                            data={singlefiedResult}
                        />
                    </div>
                </>
            )}
            {showDetail && (
                <CommunityReportDetail
                    open
                    toggleModal={handleCloseDetail}
                    performance={performance}
                />
            )}
        </>
    );
};

export default CommunityReports;
