import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { validateAll } from "indicative/validator";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import { DrawerButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import { createTeamsApi } from "../../../redux/slice/teams/teams.api";
import { InfoToast, SuccessToast } from "../../../components/elements/Alerts";

type Props = {
    open: boolean;
    handleClose: () => void;
    staff: any;
};

const CreateTeams = ({ open, handleClose, staff }: Props) => {
    const [formData, setFormData] = useState({
        teamName: "",
        teamDescription: "",
        teamLeadStaffUserId: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        try {
            const res = await createTeamsApi(data);
            if (res.successful) {
                SuccessToast(res.message);
                handleClose();
            }
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            teamName: "required",
            teamDescription: "required",
            teamLeadStaffUserId: "required",
        };

        const messages = {
            "teamName.required": "Team name is required",
            "teamDescription.required": "Team description is required",
            "teamLeadStaffUserId.required": "Please select a team lead",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create Team
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Team Name"
                        onChange={e => handleInputChange(e)}
                        name="teamName"
                        value={formData.teamName}
                        error={error.teamName}
                    />
                </div>
                <div>
                    <FloatingLabelInput
                        type="text"
                        label="Team Description"
                        onChange={e => handleInputChange(e)}
                        name="teamDescription"
                        value={formData.teamDescription}
                        error={error.teamDescription}
                    />
                </div>
                <div className="py-1">
                    <FloatingLabelSelectInput
                        options={staff}
                        label="Team Lead"
                        onChange={e => handleInputChange(e)}
                        name="teamLeadStaffUserId"
                        value={formData.teamLeadStaffUserId}
                        error={error.teamLeadStaffUserId}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Create Staff"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateTeams;
