import { validateAll } from "indicative/validator";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { SuccessToast } from "../../../components/blocks/Alert";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    createCommunityApi,
    getCommunityCategoriesApi,
} from "../../../redux/slice/communities/communities.api";

type Props = {
    open: boolean;
    handleClose: () => void;
};

const AddCommunity = ({ open, handleClose }: Props) => {
    const [formData, setFormData] = useState({
        communityName: "",
        communityCategory: "",
    });
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<any>([]);

    const fetchCommunityCategories = async () => {
        try {
            const res = await getCommunityCategoriesApi();
            const list = Object.entries(res).map(e => ({
                name: e[1],
                value: e[0],
            }));
            setCategories(list);
        } catch (err: any) {
            setLoading(false);
        }
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        try {
            const res = await createCommunityApi(data);
            if (res.successful) SuccessToast("Community created successfully");
            setLoading(false);
            handleClose();
        } catch (err: any) {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            communityName: "required",
            communityCategory: "required",
        };

        const messages = {
            "communityName.required": "Community Name is required",
            "communityCategory.required": "Please select a category",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    useEffect(() => {
        fetchCommunityCategories();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create Community
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Community Name"
                        onChange={e => handleInputChange(e)}
                        name="communityName"
                        value={formData.communityName}
                        error={error.communityName}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={categories}
                        label="Community Category"
                        onChange={e => handleInputChange(e)}
                        name="communityCategory"
                        value={formData.communityCategory}
                        error={error.communityCategory}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Create Community"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddCommunity;
