import { toast } from "react-toastify";
import getErrorMessage, {
    getAxiosErrorMessage,
} from "../../../utils/getErrorMessage";

export const SuccessToast = (
    message: string,
    location = toast.POSITION.TOP_CENTER
) =>
    toast.success(message, {
        position: location,
        autoClose: 2000,
    });

export const ErrorToast = (
    errObj: any,
    location = toast.POSITION.TOP_CENTER
) => {
    const message = getErrorMessage(errObj);

    toast.error(message, {
        position: location,
        autoClose: 2000,
    });
};

export const CustomErrorToast = (
    errObj: any,
    location = toast.POSITION.TOP_CENTER
) => {
    const message = getAxiosErrorMessage(errObj);
    toast.error(message, {
        position: location,
        autoClose: 2000,
    });
};

export const InfoToast = (
    message: string,
    location = toast.POSITION.TOP_CENTER
) => {
    toast.info(message, {
        position: location,
        autoClose: 2000,
    });
};
