import { ArrowLeftLine } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import store from "store";
import { FaAngleDown, FaPowerOff, FaAngleUp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import LolaImgMin from "../../../assets/images/logo/lola-logo-min.png";
import { adminNav, staffNav } from "./data";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [currentElement, setCurrentElement] = useState("");

    const menuNav = location.pathname.includes("admin") ? adminNav : staffNav;

    const handleSignout = () => {
        store.clearAll();
        navigate("/signin");
    };

    const handleSubMenuToggle = (itemClass?: any) => {
        setCurrentElement(itemClass);
        let ele;

        if (itemClass === undefined && currentElement !== "") {
            ele = Array.from(document.getElementsByClassName(currentElement));
            setToggle(false);
            ele[0].classList.remove("block");
            ele[0].classList.add("hidden");
            setCurrentElement("");
        }

        if (currentElement !== "") {
            ele = Array.from(document.getElementsByClassName(currentElement));
            setToggle(false);
            ele[0].classList.remove("block");
            ele[0].classList.add("hidden");
        }

        if (itemClass !== undefined) {
            setOpen(true);
            ele = Array.from(document.getElementsByClassName(itemClass));
            ele[0].classList.remove("hidden");
            ele[0].classList.add("block");
            setToggle(true);
        }

        if (toggle && currentElement !== "") {
            ele = Array.from(document.getElementsByClassName(currentElement));
            setToggle(false);
            ele[0].classList.remove("block");
            ele[0].classList.add("hidden");
            setCurrentElement("");
        }
    };

    const handleSidebarToggle = () => {
        setOpen(!open);
        handleSubMenuToggle();
    };

    useEffect(() => {
        setOpen(false);
    }, []);

    return (
        <aside className="relative z-40 block h-screen sm:z-30 sm:block sm:relative transition-all border-r border-gray-200">
            <div
                className="bg-[#F9FAFB] px-2 py-5 sm:p-3 duration-700 transition-all h-full flex flex-col gap-6 sticky z-30"
                // onMouseLeave={handleSidebarToggle}
            >
                <ArrowLeftLine
                    className={`animate-ping absolute z-10 p-1 font-semibold bg-white border-transparent rounded-full shadow-md cursor-pointer w-7 h-7 -right-3 top-16 ${
                        !open && "rotate-180"
                    }`}
                    style={{ fontSize: "1.5rem" }}
                    onClick={handleSidebarToggle}
                />
                <ArrowLeftLine
                    className={`absolute z-10 p-1 font-semibold bg-[#F9FAFB] border-transparent rounded-full shadow-sm cursor-pointer w-7 h-7 -right-3 top-16 ${
                        !open && "rotate-180"
                    }`}
                    style={{ fontSize: "1.5rem" }}
                    onClick={handleSidebarToggle}
                />
                <div className="flex items-center ">
                    <img
                        src={LolaImgMin}
                        alt="alt"
                        className={`duration-1000 cursor-pointer ${
                            open && "rotate-[90deg]"
                        }`}
                    />
                </div>
                <ul className="grow flex flex-col justify-between gap-1 duration-1000">
                    {menuNav.map(item => (
                        <div key={item.name} className="flex flex-col gap-1">
                            {item.subItems ? (
                                <li className="fex flex-col">
                                    <button
                                        type="button"
                                        aria-controls="sidebar-dropdown"
                                        className={`flex items-center text-[#667085] w-full hover:bg-[#006399] hover:border-transparent rounded-md hover:text-[#ffffff] font-semibold mb-2 ${
                                            open ? "" : ""
                                        }`}
                                        data-collapse-toggle="sidebar-dropdown"
                                        onClick={() =>
                                            handleSubMenuToggle(item.category)
                                        }
                                    >
                                        <div className="flex items-center justify-start">
                                            <div className="text-sm sm:text-2xl hover:text-[#ffffff] p-3 flex">
                                                {item.icon}
                                            </div>
                                            <div
                                                className={`grid transition-all duration-600 whitespace-nowrap 
                                            ${
                                                !open
                                                    ? "grid-cols-[0fr]"
                                                    : "grid-cols-[1fr]"
                                            }
                                            
                                                `}
                                            >
                                                <div className="overflow-hidden flex items-center gap-2">
                                                    <span className="">
                                                        {item.name}
                                                    </span>
                                                    <span
                                                        className={`ml-auto
                                                    `}
                                                    >
                                                        {toggle ? (
                                                            <FaAngleUp />
                                                        ) : (
                                                            <FaAngleDown />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                    <ul
                                        id="dropdown-example"
                                        className={`text-xs grid transition-all ${
                                            item.category
                                        } ${
                                            !toggle
                                                ? "grid-rows-[0fr]"
                                                : "grid-rows-[1fr]"
                                        }`}
                                    >
                                        <li className="overflow-hidden pl-4">
                                            {item.subItems.map(subItem => (
                                                <Link
                                                    to={subItem.url}
                                                    key={
                                                        item.name + subItem.name
                                                    }
                                                    className={`relative flex items-center text-sm text-[#667085] bg-transparent  hover:bg-gray-900 py-3 hover:text-white rounded px-2 font-semibold 
                                                    ${!open ? "w-0" : "w-full"}
                                                    `}
                                                >
                                                    <span>{subItem.name}</span>
                                                </Link>
                                            ))}
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                <Link
                                    to={item.url}
                                    key={item.id}
                                    className={`relative flex items-center text-sm rounded-md font-semibold cursor-pointer border-0 
                                        ${
                                            location.pathname.includes(item.url)
                                                ? "bg-[#667085] text-orange-400 hover:bg-[#667085] hover:text-orange-400 "
                                                : "bg-transparent text-[#667085] hover:bg-orange-400 hover:text-[#ffffff]"
                                        }  
                                        `}
                                >
                                    <div className="text-sm sm:text-2xl p-3 flex">
                                        {item.icon}
                                    </div>
                                    <div
                                        className={`grid transition-all duration-600 ${
                                            !open
                                                ? "grid-cols-[0fr]"
                                                : "grid-cols-[1fr]"
                                        } origin-left `}
                                    >
                                        <span className="overflow-hidden whitespace-nowrap font-avenirBook">
                                            {item.name}
                                        </span>
                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
                    <li className="text-sm rounded-md font-semibold cursor-pointer hover:bg-red-500 hover:text-white gap-x-4 mt-auto">
                        <div
                            onClick={handleSignout}
                            role="button"
                            className="flex items-center"
                        >
                            <div className="text-sm sm:text-xl p-3 flex">
                                <FaPowerOff className="text-inherit size-6" />
                            </div>

                            <Link
                                to="/"
                                className={`${
                                    !open && "hidden"
                                } origin-left duration-200 hover:no-underline font-avenirBook`}
                            >
                                Logout
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;
