import React from "react";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import formatDate from "../../../utils/formatDate";

type Props = {
    open: boolean;
    toggleModal: () => void;
    performance: any;
};

const CommunityReportDetail = ({ open, toggleModal, performance }: Props) => (
    <DrawerPanel
        open={open}
        handleClose={toggleModal}
        title="Performance Detail"
    >
        {performance.map((result: any, index: any) => (
            <div className="pl-4 mt-2 mb-8" key={result.day}>
                <h6 className="text-sm font-semibold text-[#0B4665]">
                    {result.day}
                </h6>
                {result.entries.map((entry: any) => (
                    <div className="pl-4 mt-4 mb-8">
                        <h6 className="text-sm font-semibold text-[#f75500]">
                            {entry.hour}
                        </h6>
                        {entry.entries.map((e: any) => (
                            <>
                                <div className="pl-4 my-4">
                                    <h6 className="text-sm font-semibold text-[#0B4665] mb-2">
                                        {e.hourMinuteStamp}
                                    </h6>
                                    <ul>
                                        <li className="text-sm">
                                            <span className="font-semibold">
                                                Customer Name:
                                            </span>{" "}
                                            {e.record.customerNameCalled}
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                Customer Phone:
                                            </span>{" "}
                                            {e.record.customerPhoneNumber}
                                        </li>
                                        <li>
                                            <span className="font-semibold">
                                                Follow Up Date:
                                            </span>{" "}
                                            {formatDate(e.record.followUpDate)}
                                        </li>
                                    </ul>
                                </div>
                                <hr />
                            </>
                        ))}
                    </div>
                ))}
            </div>
        ))}
    </DrawerPanel>
);

export default CommunityReportDetail;
