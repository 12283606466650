import React, { useId, useState } from "react";
import { useNavigate } from "react-router";
import {
    ErrorToastWString,
    SuccessToast,
} from "../../../components/elements/Alerts";
import { CustomButton } from "../../../components/elements/Button";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import Loading from "../../../components/elements/Loading/Loading";
import {
    autoDebitCustomerApi,
    generateCredentialsApi,
    updateCustomerNameApi,
} from "../../../redux/slice/customers/customers.api";
import formatDate from "../../../utils/formatDate";
import numberFormat from "../../../utils/numberFormat";
import ActivateAutoDebit from "./ActivateAutoDebit";
import AssignAgent from "./AssignAgent";
import AssignFSO from "./AssignFSO";
import EdittableField from "../../../components/elements/Inputs/EdittableField";
import SmsHistory from "./smsHistory";
import UpdateUser from "./UpdateUser";
import UpdateUserNin from "./UpdateUserNin";

// import UpdateUser from './UpdateUser'
// import UpdateUserNin from './UpdateUserNin'

type RowProps = {
    title: string;
    value: any;
    valueStyle?: string;
};

const Row = ({ title, value, valueStyle }: RowProps) => (
    <div className="flex items-center justify-between py-2 text-xs sm:text-sm">
        <div className="text-left">{title}:</div>
        <div
            className={`font-semibold tracking-wider text-right ${valueStyle}`}
        >
            {value}
        </div>
    </div>
);

Row.defaultProps = {
    valueStyle: "",
};

type Props = {
    open: boolean;
    toggleModal: () => void;
    customer: any;
};

const initialActionState = { addBvn: false };

const CustomerDetail = ({ open, toggleModal, customer }: Props) => {
    const id = useId();
    const navigate = useNavigate();
    const [shouldAssign, setShouldAssign] = useState<any>({
        isFSO: false,
        isPOS: false,
    });
    const [loading, setLoading] = useState(false);
    const [activateAutoDebit, setActivateAutoDebit] = useState(false);

    const [viewHistory, setViewHistory] = useState(false);

    const [actions, setActions] =
        useState<Record<string, boolean>>(initialActionState);

    const updateAction = (action: string) => {
        setActions({ ...actions, [action]: true });
    };

    const closeActionsAfterUpdates = () => {
        setActions(initialActionState);
        // handleClose(true)
    };

    const handleAssign = (choice: any) => {
        setShouldAssign({
            [choice]: true,
        });
    };

    const handleCancelAssign = () => {
        setShouldAssign({
            isFSO: false,
            isPOS: false,
        });
    };

    const handleDisableAutoDebit = async (data: any) => {
        setLoading(true);

        try {
            const res = await autoDebitCustomerApi(data);
            if (res.successful) {
                SuccessToast(
                    "Auto-debit disabled! Please click refresh to get updated data."
                );
            }

            if (!res.successful) {
                ErrorToastWString("Error in activating Auto-debit");
            }
            setLoading(false);
            toggleModal();
        } catch (err) {
            setLoading(false);
        }
    };

    const handleAutoDebit = () => {
        if (customer.loa_autoDebittingEnabled) {
            const payload = {
                lolaCustomerUserId: customer.id,
                newStatus: false,
                amount: customer.loa_dailySavingsAmount,
            };
            handleDisableAutoDebit(payload);
        }

        if (!customer.loa_autoDebittingEnabled) {
            setActivateAutoDebit(true);
        }
    };

    const handleEditFirstname = async (value: string) => {
        try {
            const payload = {
                firstName: value,
                lastName: customer.last_name,
                phoneNumber: customer.phone_number,
            };

            await updateCustomerNameApi(payload);
            SuccessToast("Updated Successfully");
        } catch (err) {
            //
            ErrorToastWString("Update Failed");
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return true;
        }
    };

    const handleEditLastname = async (value: string) => {
        try {
            const payload = {
                lastName: value,
                firstName: customer.first_name,
                phoneNumber: customer.phone_number,
            };

            await updateCustomerNameApi(payload);
            SuccessToast("Updated Successfully");
        } catch (err) {
            //
            ErrorToastWString("Update Failed");
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return true;
        }
    };

    const handleGenerate = async () => {
        const payload = {
            userId: customer.id,
        };
        try {
            const res = await generateCredentialsApi(payload);
            if (!res.successful) {
                ErrorToastWString("Something went Error. Please Try again");
            }
            SuccessToast(
                "Password and Pin created successully and sent to customer's phone"
            );
            toggleModal();
        } catch (err) {
            setLoading(false);
        }
    };

    const viewDetails = (phone: string) =>
        navigate("/workspaces", { state: phone });

    return (
        <>
            <DrawerPanel
                open={open}
                handleClose={toggleModal}
                title="Customer Detail"
            >
                {viewHistory ? (
                    <SmsHistory customer={customer} />
                ) : (
                    <div className="px-1 py-2 sm:px-2 font-gilroy">
                        <div className="flex justify-between mb-6">
                            <div className="text-right">
                                <CustomButton
                                    buttonStyle="bg-[#0B4665] text-white px-4 hover:bg-[#1270a1] py-2 text-xs"
                                    onClick={handleAutoDebit}
                                >
                                    {loading ? (
                                        <Loading size="xs" />
                                    ) : customer.loa_autoDebittingEnabled ? (
                                        "Disable Auto-debit"
                                    ) : (
                                        "Enable Auto-debit"
                                    )}
                                </CustomButton>
                            </div>
                            <div className="text-right">
                                <CustomButton
                                    buttonStyle="bg-[#F75500] text-white px-4 hover:bg-[#ff681a] py-2 text-xs"
                                    onClick={() => handleAssign("isFSO")}
                                >
                                    Assign FSO
                                </CustomButton>
                            </div>
                        </div>
                        <div className="mb-2 text-sm bg-white">
                            {customer.posAgentRecords !== null && (
                                <>
                                    <h3 className="mb-2 text-base font-bold text-[#F75500]">
                                        POS Agent(s) Information
                                    </h3>
                                    {customer.posAgentRecords.map(
                                        (agent: any) => (
                                            <div
                                                className="px-0 mb-4"
                                                key={`${id}_${agent.fullName}`}
                                            >
                                                <Row
                                                    title="Agent Name"
                                                    value={agent.fullName}
                                                />
                                                <Row
                                                    title="Agent Phone Number"
                                                    value={agent.phoneNumber}
                                                />
                                                <Row
                                                    title="Is Primary Agent"
                                                    value={
                                                        agent.primary
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                />
                                                <Row
                                                    title="Date Assigned"
                                                    value={formatDate(
                                                        agent.createdAt
                                                    )}
                                                />
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                            {customer.walletRecords !== null && (
                                <div
                                    className="px-0 my-10"
                                    key={`${id}_${customer.walletRecords.virtualAccountNumber}`}
                                >
                                    <h3 className="mb-2 text-base font-bold text-[#F75500]">
                                        Wallet Details
                                    </h3>
                                    <Row
                                        title="Account Number"
                                        value={
                                            customer.walletRecords
                                                .virtualAccountNumber
                                        }
                                    />
                                    <Row
                                        title="Bank Name"
                                        value={
                                            customer.walletRecords
                                                .virtualAccountBankName
                                        }
                                    />
                                    <Row
                                        title="Available Balance"
                                        value={
                                            customer.walletRecords
                                                .avaialbleBalance
                                        }
                                    />
                                    <Row
                                        title="Bonus Balance"
                                        value={
                                            customer.walletRecords.bonusBalance
                                        }
                                    />
                                    <Row
                                        title="Easy Savings Account Number"
                                        value={
                                            customer.easy_savings_account_number
                                        }
                                    />
                                    <Row
                                        title="Auto Debit Enabled"
                                        value={
                                            customer.loa_autoDebittingEnabled
                                                ? "Enabled"
                                                : "Disabled"
                                        }
                                        valueStyle={
                                            customer.loa_autoDebittingEnabled
                                                ? "text-green-500"
                                                : "text-red-500"
                                        }
                                    />
                                    <Row
                                        title="Daily Savings Amount"
                                        value={`₦ ${numberFormat(
                                            customer.loa_dailySavingsAmount
                                        )}`}
                                    />
                                </div>
                            )}
                            <div className="px-0 my-10 flex flex-col gap-3">
                                <div className="flex items-center justify-between">
                                    <h3 className="mb-2 text-xs sm:text-base font-bold text-[#F75500]">
                                        User Information
                                    </h3>
                                    <div
                                        role="button"
                                        className="underline cursor-pointer text-xs text-[#0B4665] hover:text-[#1270a1] font-base"
                                        onClick={() =>
                                            viewDetails(customer.phone_number)
                                        }
                                    >
                                        View Customer
                                    </div>
                                </div>
                                {/* <Row
                                title="First Name"
                                value={customer.first_name}
                            /> */}
                                <EdittableField
                                    title="First Name"
                                    initialValue={customer.first_name}
                                    handleSubmit={handleEditFirstname}
                                />
                                <EdittableField
                                    title="Last Name"
                                    initialValue={customer.last_name}
                                    handleSubmit={handleEditLastname}
                                />
                                <Row
                                    title="Phone Number"
                                    value={customer.phone_number}
                                />
                                <div className="flex items-end justify-between">
                                    <Row title="BVN" value={customer.bvn} />
                                    {customer.bvn === "not provided" && (
                                        <button
                                            type="button"
                                            className="rounded p-2 bg-indigo-600 text-white font-semibold text-xs"
                                            onClick={() =>
                                                updateAction("addBvn")
                                            }
                                        >
                                            Add BVN
                                        </button>
                                    )}
                                </div>
                                <div className="flex items-end justify-between">
                                    <Row title="NIN" value={customer.nin} />
                                    {customer.bvn === "not provided" && (
                                        <button
                                            type="button"
                                            className="rounded p-2 bg-indigo-600 text-white font-semibold text-xs"
                                            onClick={() =>
                                                updateAction("addNin")
                                            }
                                        >
                                            Add NIN
                                        </button>
                                    )}
                                </div>
                                <Row
                                    title="Date Created"
                                    value={formatDate(customer.created_at)}
                                />
                            </div>
                            <div className="mt-8 text-center">
                                <CustomButton
                                    buttonStyle="bg-[#0B4665] text-white  px-10 hover:bg-[#1270a1] py-3"
                                    onClick={() => handleAssign("isPOS")}
                                >
                                    Assign POS Agent
                                </CustomButton>
                            </div>
                            <div
                                className="flex justify-center items-center mt-6 text-[#f75500] font-bold hover:underline cursor-pointer text-xs sm:text-sm"
                                role="button"
                                onClick={handleGenerate}
                            >
                                {loading ? (
                                    <Loading size="sm" />
                                ) : (
                                    <p>
                                        Click here to generate a new password
                                        and pin
                                    </p>
                                )}
                            </div>
                            <div className="flex-flex-col mt-3">
                                <button
                                    type="button"
                                    className="p-1 rounded bg-slate-300 hover:bg-slate-200 font-semibold w-full "
                                    onClick={() => setViewHistory(true)}
                                >
                                    Click to view SMS/Pin History
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </DrawerPanel>
            {actions.addBvn && (
                <UpdateUser
                    handleClose={closeActionsAfterUpdates}
                    memberData={customer}
                />
            )}
            {actions.addNin && (
                <UpdateUserNin
                    handleClose={closeActionsAfterUpdates}
                    memberData={customer}
                />
            )}
            {shouldAssign.isPOS && (
                <AssignAgent
                    open
                    handleClose={handleCancelAssign}
                    customer={customer}
                />
            )}
            {shouldAssign.isFSO && (
                <AssignFSO
                    open
                    handleClose={handleCancelAssign}
                    entity={customer}
                    entityCategory="user"
                />
            )}
            {activateAutoDebit && (
                <ActivateAutoDebit
                    open
                    handleClose={toggleModal}
                    id={customer.id}
                />
            )}
        </>
    );
};

export default CustomerDetail;
