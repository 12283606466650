import React, { ChangeEvent } from "react";
import { MdSearch } from "react-icons/md";

type Props = {
    value: string;
    placeholder?: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    name: string;
};

const SearchInput = ({
    value,
    placeholder,
    disabled,
    onChange,
    name,
}: Props) => (
    <div className="flex items-center bg-[#f5f5f5] px-2 w-full rounded-md">
        <MdSearch size="20px" className="ml-2" />

        <input
            className="block py-2.5 pl-2 w-full text-sm  bg-transparent border-0 focus:outline-none text-extrabold"
            type="text"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    </div>
);

SearchInput.defaultProps = {
    placeholder: "Search", // label for the input
    disabled: false, // if the input is disabled
    // onChange: (e: ChangeEvent<HTMLInputElement>) => {}, // onChange callback
};

export default SearchInput;
