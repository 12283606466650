import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./slice/auth/AuthSlice";
import terminalAPI from "./slice/terminals";

const rootReducer = combineReducers({
    auth: authReducer,
    [terminalAPI.reducerPath]: terminalAPI.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
