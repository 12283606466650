export const getAxiosErrorMessage = (errObj: any) => {
    const errResponse = errObj.response || null;
    const message =
        "Your request couldn't be processed, We have notified the technical team about it";
    let errMessage;
    if (errResponse === null) {
        errMessage = message;
    } else if (
        errResponse &&
        errResponse.data &&
        errResponse.data.message.message
    ) {
        errMessage = errResponse.data.message.message;
    } else if (errResponse.data.message) {
        errMessage = errResponse.data.message;
    } else {
        errMessage = message;
    }

    return errMessage;
};

const getErrorMessage = (errObj: any) => {
    const errResponse = errObj || null;

    const message =
        "Your request couldn't be processed, We have notified the technical team about it";
    let errorMessage;

    if (errResponse === null) {
        errorMessage = message;
    } else if (errResponse && errResponse.message) {
        errorMessage = errResponse.message;
    } else if (errResponse.message.message) {
        errorMessage = errResponse.message.message;
    } else if (errResponse.message.message.message) {
        errorMessage = errResponse.message.message.message;
    } else {
        errorMessage = message;
    }

    return errorMessage;
};

export default getErrorMessage;
