/* eslint-disable react/react-in-jsx-scope */
import { Fragment, useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical } from "react-icons/hi";
import { Button, Dropdown } from "rsuite";
import PosIcon from "../../../assets/images/logo/pos-terminal 1.png";
import { InfoToast } from "../../../components/elements/Alerts";
import Loading from "../../../components/elements/Loading/Loading";
import { DbAllTerminals } from "../../../types/api.types";
import capitalizeFistLetterInSentence from "../../../utils/capitalizeFirstLetter";
import formatDate from "../../../utils/formatDate";
import ReAssignTerminal from "./ReassignTerminal";
import TerminalDetails from "./TerminalDetails";

export type SelectionType = {
    action: "details" | "re-assign";
    data: DbAllTerminals;
};

type Props = {
    allTerminals: DbAllTerminals[];
    loading: boolean;
    onTerminalDetailsClick(row: DbAllTerminals): void;
};

const RenderIconButton = (props: any, ref: any) => (
    <Button type="button" ref={ref} {...props}>
        <HiDotsVertical />
    </Button>
);

const terminalDropdownActions = (isAssigned: boolean) =>
    [
        { title: "Terminal Details", slug: "detail" },
        {
            title: isAssigned ? "Re-assign Terminal" : "Assign Terminal",
            slug: "reassign",
        },
        // { title: "Terminal History", slug: "terminalHistory" }, removed for the time being
        { title: "De-activate Terminal", slug: "deactivate" },
    ] as const;

const AllTerminals = (props: Props) => {
    const {
        allTerminals,
        loading,
        onTerminalDetailsClick: _actionForTerminalHistory,
    } = props;

    const [selection, setSelection] = useState<SelectionType>();

    const handleClick = (idx: number, row: DbAllTerminals): void => {
        const { isAssigned } = row;

        const { slug, title } = terminalDropdownActions(Boolean(isAssigned))[
            idx
        ];

        switch (slug) {
            case "detail":
                return setSelection({ action: "details", data: row });
            case "reassign":
                return setSelection({ action: "re-assign", data: row });
            default:
                return InfoToast(`Feature not available yet for  "${title}"`);
        }
    };

    const handleSelectionClose = () => setSelection(undefined);

    const columns = [
        {
            name: "S/N",
            selector: (row: DbAllTerminals) => row?.serialNumber || "N/A",
        },
        {
            name: "Terminal Id",
            selector: (row: DbAllTerminals) => row?.terminalId ?? "N/A",
        },
        {
            name: "Is Active",
            selector: (row: DbAllTerminals) =>
                capitalizeFistLetterInSentence(String(row?.isActive ?? "N/A")),
        },
        {
            name: "Is Assigned",
            selector: (row: DbAllTerminals) =>
                capitalizeFistLetterInSentence(
                    String(row?.isAssigned ?? "N/A")
                ),
        },
        {
            name: "Updated At",
            selector: (row: DbAllTerminals) => formatDate(row.createdAt as any),
        },
        {
            cell: useCallback(
                (row: DbAllTerminals) => (
                    <Dropdown
                        renderToggle={RenderIconButton}
                        placement="bottomEnd"
                    >
                        <div className="mt-2">
                            {terminalDropdownActions(
                                Boolean(row.isAssigned)
                            ).map(({ title, slug }, idx) => (
                                <Dropdown.Item
                                    onClick={() => handleClick(idx, row)}
                                    key={title}
                                >
                                    {title}
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown>
                ),
                []
            ),
        },
    ];

    return (
        <div>
            <div className="w-full">
                <div>
                    {allTerminals.length < 0 ? (
                        <div className="flex flex-col items-center justify-center mt-14">
                            <div className="items-center justify-center w-20 h-16 mb-4">
                                <img src={PosIcon} alt="" />
                            </div>
                            <div>
                                <p className="w-64 h-20 text-sm font-normal text-center font-avenirBook">
                                    You don&apos;t have any active POS Terminal
                                    assigned to your community. Request a new
                                    terminal to get started
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="terminal-table">
                            <DataTable
                                data={allTerminals}
                                columns={columns}
                                highlightOnHover
                                responsive
                                pointerOnHover
                                subHeader
                                subHeaderWrap
                                progressPending={loading}
                                progressComponent={<Loading />}
                                // customStyles={{
                                //     table: {
                                //         style: { paddingBottom: "150px" },
                                //     },
                                // }}
                                pagination
                            />
                        </div>
                    )}
                </div>
            </div>

            {selection && (
                <Fragment key="selection--fragment">
                    {selection.action === "details" && (
                        <TerminalDetails
                            open
                            toggleModal={handleSelectionClose}
                            terminalID={selection.data.terminalId}
                        />
                    )}
                    {selection.action === "re-assign" && (
                        <ReAssignTerminal
                            row={selection.data}
                            handleClose={() => {}}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default AllTerminals;
