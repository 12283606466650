export const getCookie = (name: any) => {
    const pattern = RegExp(`${name}=.[^;]*`);
    const matched = document.cookie.match(pattern);
    if (matched) {
        const cookie = matched[0].split("=");
        return cookie[1];
    }
    return false;
};

export const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value};path=/;domain=localhost;`;
};
