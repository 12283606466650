import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";

type Props = {
    open: boolean;
    handleClose: () => void;
    handleFilter: (query: any) => void;
};

const FilterCustomers = ({ open, handleClose, handleFilter }: Props) => {
    const [formData, setFormData] = useState({
        communityId: "",
        fsoStaffUserId: "",
        userPhoneNumber: "",
        userAccountNumber: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Filter Customers
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Phone Number"
                        onChange={e => handleInputChange(e)}
                        name="userPhoneNumber"
                        value={formData.userPhoneNumber}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Account Number"
                        onChange={e => handleInputChange(e)}
                        name="userAccountNumber"
                        value={formData.userAccountNumber}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Frontline Sales Officer"
                        onChange={e => handleInputChange(e)}
                        name="fsoStaffUserId"
                        value={formData.fsoStaffUserId}
                    />
                </div>

                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Community"
                        onChange={e => handleInputChange(e)}
                        name="communityId"
                        value={formData.communityId}
                    />
                </div>
            </Modal.Body>
            <div className="flex flex-col justify-center">
                <DrawerButton onClick={() => handleFilter(formData)}>
                    Filter
                </DrawerButton>
                <Button
                    onClick={handleClose}
                    className="text-red-600 bg-slate-100"
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default FilterCustomers;
