import React, { useEffect, useState } from "react";
import store from "store";
import { validateAll } from "indicative/validator";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    assignStaffApi,
    getAllStaffApi,
} from "../../../redux/slice/staff/staff.api";
import {
    ErrorToastWString,
    SuccessToast,
} from "../../../components/elements/Alerts";

type Props = {
    open: boolean;
    handleClose: () => void;
    entity: any;
    entityCategory: string;
};

const AssignFSO = ({ open, handleClose, entity, entityCategory }: Props) => {
    const userId = store.get("userId");
    const [formData, setFormData] = useState({
        lolaStaffUserId: "",
        entityCategory,
        entityId: entity.id,
        assignerStaffUserId: userId,
    });
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");

    const fetchStaff = async () => {
        setLoading(true);
        try {
            const res = await getAllStaffApi();
            const staffObj = res.staffsResultSet.map((d: any) => ({
                name: `${d.userRecord.first_name} ${d.userRecord.last_name}`,
                value: d.userId,
            }));
            setStaff(staffObj);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processAssign = async (data: any) => {
        setLoading(true);
        try {
            const res = await assignStaffApi(data);
            if (!res.successful) ErrorToastWString(res.message);
            if (res.successful) {
                SuccessToast("FSO successfully assigned");
                handleClose();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            lolaStaffUserId: "required",
        };

        const messages = {
            "lolaStaffUserId.required": "FSOS Staff is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processAssign(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });

        setError({});
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Assign POS Agent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="pb-1">
                        <FloatingLabelSelectInput
                            options={staff}
                            label="FSO Staff"
                            onChange={e => handleInputChange(e)}
                            name="lolaStaffUserId"
                            value={formData.lolaStaffUserId}
                            error={error.lolaStaffUserId}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <DrawerButton onClick={handleSubmit}>
                            {loading ? <Loading size="sm" /> : "Assign Agent"}
                        </DrawerButton>
                        <Button
                            onClick={handleClose}
                            className="text-red-600 bg-slate-100 hover:text-red-600"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AssignFSO;
