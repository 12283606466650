/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import store from "store";
import { FaSync, FaWallet } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import {
    syncTransactionDataApi,
    syncUserDataApi,
} from "../../../redux/slice/syncdata/syncdata.api";
import { reversePendingTransactionsApi } from "../../../redux/slice/transactions/transactions.api";
import capitalizeFistLetterInSentence from "../../../utils/capitalizeFirstLetter";
import { SuccessToast } from "../../blocks/Alert";
import { CustomButton } from "../../elements/Button";
import Loading from "../../elements/Loading/Loading";

const DashboardHeader = () => {
    const userRecord = store.get("userRecord");
    const [loading, setLoading] = useState({
        users: false,
        wallet: false,
        reverse: false,
    });

    const handleSync = async (choice: string) => {
        setLoading({
            ...loading,
            [choice]: true,
        });

        if (choice === "users") {
            try {
                const res = await syncUserDataApi();
                if (res.successful) SuccessToast(res.message);
            } catch (err) {
                setLoading({
                    users: false,
                    wallet: false,
                    reverse: false,
                });
            }
        }

        if (choice === "wallet") {
            try {
                const res = await syncTransactionDataApi();
                if (res.successful) SuccessToast(res.message);
            } catch (err) {
                setLoading({
                    users: false,
                    wallet: false,
                    reverse: false,
                });
            }
        }

        if (choice === "reverse") {
            try {
                const res = await reversePendingTransactionsApi();
                SuccessToast(res.message);
            } catch (err) {
                setLoading({
                    users: false,
                    wallet: false,
                    reverse: false,
                });
            }
        }

        setLoading({
            users: false,
            wallet: false,
            reverse: false,
        });
    };

    return (
        <>
            <div className="flex flex-wrap gap-2 items-center justify-between sm:px-8 py-4 border-b-[1px] border-slate-100 bg-[#F9FAFB] sticky top-0 z-20 px-4 ">
                <div className="userprofile ">
                    <div className="text-base font-bold sm:text-lg sm:font-extrabold font-gilroyBold">
                        Dashboard
                    </div>
                    <div className="text-xs font-normal sm:text-sm span-0 font-avenirBook font">
                        Welcome back,{" "}
                        <span className="font-extrabold">
                            {userRecord !== undefined
                                ? capitalizeFistLetterInSentence(
                                      userRecord.first_name
                                  )
                                : ""}{" "}
                            👋
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-end gap-3 lg:justify-center heading__icons">
                    <CustomButton
                        className="text-xs font-semibold text-white rounded py-3 bg-slate-700 px-4 hover:bg-slate-600 flex items-center justify-center gap-1"
                        onClick={() => handleSync("reverse")}
                    >
                        {loading.reverse ? (
                            <Loading size="sm" />
                        ) : (
                            <>
                                <GiReturnArrow className="w-3 h-3" />
                                Reverse Pending Transactions
                            </>
                        )}
                    </CustomButton>
                    <CustomButton
                        className="bg-[#009FF5] text-xs py-3 px-4 text-white hover:bg-[#0095e6] font-semibold rounded flex items-center justify-center gap-1"
                        onClick={() => handleSync("users")}
                    >
                        {loading.users ? (
                            <Loading size="sm" />
                        ) : (
                            <>
                                <FaSync className="w-3 h-3" />
                                Sync Users
                            </>
                        )}
                    </CustomButton>
                    <CustomButton
                        className="bg-[#F75500] py-3 px-4 text-xs text-white hover:bg-[#ff681a] font-semibold rounded flex items-center justify-center gap-1"
                        onClick={() => handleSync("wallet")}
                    >
                        {loading.wallet ? (
                            <Loading size="sm" />
                        ) : (
                            <>
                                <FaWallet className="w-3 h-3" />
                                Sync Wallets & Transactions
                            </>
                        )}
                    </CustomButton>
                </div>
            </div>
            {/* <DrawerPanel
                open={open}
                handleClose={handleClose}
                title="Notification"
            >
                <h1>Demo</h1>
            </DrawerPanel> */}
        </>
    );
};

export default DashboardHeader;
