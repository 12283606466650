import React from "react";

type FloatingLabelProps = {
    options: {
        name: string;
        [key: string]: string | number;
    }[];
    name?: string;
    value?: string;
    label?: string;
    required?: boolean;
    onChange?: (e: any) => void;
    onFocus?: () => void;
    error?: string;
    status?: string;
};

const FloatingLabelSelectInput = ({
    name,
    value,
    label,
    required,
    onChange,
    onFocus,
    error,
    status,
    options,
}: FloatingLabelProps) => (
    <div className="relative z-0 w-full my-4 group font-avenirBook">
        <select
            className="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[2px] border-[#101828]/20 appearance-none focus:outline-none focus:ring-0 focus:border-blue-300 peer"
            id={name}
            placeholder=" "
            onChange={onChange}
            onFocus={onFocus}
            name={name}
            value={value}
            required={required}
        >
            <option value="">Select an option</option>
            {options.map(d => (
                <option value={d.value} key={d.name}>
                    {d.name}
                </option>
            ))}
        </select>
        <label
            htmlFor={name}
            className="absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
            {label || "Input Label"}
            {required && <span className="text-red-600 ">*</span>}
        </label>
        {error && (
            <p className="mt-2 text-xs text-red-600 ">
                <span className="font-medium">{error}</span>
            </p>
        )}
        {status && (
            <p className="mt-2 text-sm text-slate-600 ">
                <span className="font-light">{status}</span>
            </p>
        )}
    </div>
);

FloatingLabelSelectInput.defaultProps = {
    name: "", // name of the input
    value: "", // value of the input
    label: "Input Label", // label of the input
    required: false, // whether the input is required
    onChange: (_: any) => {}, // callback when the input changes
    onFocus: () => {}, // callback when the input gains focus
    error: "", // error message
    status: "", // status message
};

export default FloatingLabelSelectInput;
