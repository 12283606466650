import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { validateAll } from "indicative/validator";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import {
    assignStaffToTeamApi,
    getTeamsApi,
} from "../../../redux/slice/teams/teams.api";
import Loading from "../../../components/elements/Loading/Loading";
import { InfoToast, SuccessToast } from "../../../components/elements/Alerts";

type Props = {
    open: boolean;
    handleClose: () => void;
    userId: string;
};

const AssignStaffToTeam = ({ open, handleClose, userId }: Props) => {
    const [formData, setFormData] = useState({ teamId: "" });
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processAssign = async (data: any) => {
        setLoading(true);

        const payload = {
            lolaStaffUserId: userId,
            teamId: data.teamId,
        };
        try {
            const res = await assignStaffToTeamApi(payload);
            if (res.successful) {
                SuccessToast(res.message);
                handleClose();
            }
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            teamId: "required",
        };

        const messages = {
            "teamId.required": "Please select a team",
        };

        validateAll(formData, rules, messages)
            .then(() => processAssign(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const fetchTeams = async () => {
        setLoading(true);
        try {
            const res = await getTeamsApi();
            if (res.successful) {
                const teamObj = res.teamList.map((e: any) => ({
                    name: e.teamName,
                    value: e["_id"],
                }));
                setTeams(teamObj);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Assign Staff to Team
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="pb-1">
                        <FloatingLabelSelectInput
                            options={teams}
                            label="Team"
                            onChange={e => handleInputChange(e)}
                            name="teamId"
                            value={formData.teamId}
                            error={error.teamId}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <DrawerButton onClick={handleSubmit}>
                            {loading ? <Loading size="sm" /> : "Assign Team"}
                        </DrawerButton>
                        <Button
                            onClick={handleClose}
                            className="text-red-600 bg-slate-100 hover:text-red-600"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AssignStaffToTeam;
