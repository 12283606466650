import React from "react";
import { Navigate, Outlet } from "react-router";
import store from "store";

const AuthGuard = () => {
    const user = store.get("accessToken");
    const isAuthenticated = typeof user !== "undefined";

    return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
};

export default AuthGuard;
