import { validateAll } from "indicative/validator";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Modal } from "rsuite";
import {
    CustomErrorToast,
    SuccessToast,
} from "../../../components/elements/Alerts";
// import Button from '../../../components/elements/Button'
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import { MainButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import {
    getCustomerByPhoneApi,
    verifyCustomerNINApi,
} from "../../../redux/slice/customers/customers.api";

type Props = {
    handleClose: () => void;
    memberData: Record<string, any>;
};

const UpdateUserNin = ({ handleClose, memberData }: Props) => {
    const [formData, setFormData] = useState<Record<string, any>>({
        memberNin: "",
        memberDate: "",
    });
    const [error, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);

    const [fullDetails, setFullDetails] = useState<any | null>(null);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processUpdate = async (data: Record<string, any>) => {
        const payload = {
            identifier: data.memberNin,
            dob: data.memberDate,
            walletKey: fullDetails.wallet_key,
            type: "nin",
        };
        setLoading(true);
        try {
            const res: Record<string, any> = await verifyCustomerNINApi(
                payload
            );
            console.log(res);
            SuccessToast("Nin Updated Succesfully");
            handleClose();
        } catch (err: any) {
            console.log("Error: ", err);
            CustomErrorToast({ message: err.response.data.message });
            setLoading(false);
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            memberNin: "required",
            memberDate: "required",
        };
        const messages = {
            "memberNin.required": "NIN is required",
            "memberDate.required": "Date of Birth is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processUpdate(formData))
            .catch(errs => {
                const formattedErrors: any = {};
                errs.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    useEffect(() => {
        const getCustomerDetails = async () => {
            try {
                const res = await getCustomerByPhoneApi(
                    memberData.phone_number
                );

                setFullDetails(res.users[0]);
            } catch (err: any) {
                console.log(err);
            }
        };
        getCustomerDetails();
    }, []);

    return (
        <Modal
            open
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-semibold font-avenirBook">
                    Update Customer Detail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="">
                    <FloatingLabelInput
                        type="number"
                        onChange={handleInputChange}
                        label="Enter Customer's NIN"
                        error={error.memberNin}
                        name="memberNin"
                        value={formData.memberNin}
                    />
                    <FloatingLabelInput
                        type="date"
                        onChange={handleInputChange}
                        label="Enter Date of Birth"
                        error={error.memberDate}
                        name="memberDate"
                        value={formData.memberDate}
                    />
                    <div className="ml-auto w-fit">
                        <MainButton
                            buttonStyle="bg-[#009FF5] text-white rounded !font-bold"
                            onClick={handleSubmit}
                        >
                            {loading ? <Loading size="xs" /> : "Submit"}
                        </MainButton>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateUserNin;
