import React from "react";

type TextAreaProps = {
    rows?: number;
    label?: string;
    placeholder?: string;
    name?: string;
    disabled?: boolean;
    value?: string;
    required?: boolean;
    error?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
};

const TextAreaGroup = ({
    name,
    value,
    placeholder,
    label,
    required,
    onChange,
    error,
    disabled,
    rows,
    onFocus,
}: TextAreaProps) => (
    <div className="my-6">
        <label
            htmlFor={name}
            className="block mb-2 text-xs text-gray-500 dark:text-gray-400"
        >
            {label}
        </label>
        <textarea
            id={name}
            rows={rows}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            onChange={onChange}
            onFocus={onFocus}
            name={name}
            value={value}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
        />
        {error && (
            <p className="mt-2 text-xs text-red-600 ">
                <span className="font-medium">{error}</span>
            </p>
        )}
    </div>
);

TextAreaGroup.defaultProps = {
    rows: 4, // number of rows
    label: "TextArea Label", // label for the textarea
    placeholder: "TextArear Placeholder", // default value
    name: "textarea", // name of the textarea
    value: "", // default value
    required: false, // default value
    error: "", // error message
    onChange: () => {}, // callback when the input changes
    onFocus: () => {}, // callback when the input gains focus
    disabled: false, // whether the input is disabled
}; // default props

export default TextAreaGroup;
