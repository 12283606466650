import { validateAll } from "indicative/validator";
import React, { useState } from "react";
import PosMachine from "../../../assets/images/pos-terminal.png";
import { CustomButton } from "../../../components/elements/Button";
import ModalBox from "../../../components/elements/ModalBox/ModalBox";
import {
    ErrorToastWString,
    SuccessToast,
} from "../../../components/elements/Alerts";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import { CreateTerminalType } from "../../../types/api.types";
import { CreateTerminalApi } from "../../../redux/slice/posterminals/posterminals.api";
import Loading from "../../../components/elements/Loading/Loading";

type Props = {
    toggleModal(): void;
};

const CreateTerminal = ({ toggleModal }: Props) => {
    const [formData, setFormData] = useState<CreateTerminalType>({
        terminalId: "",
        serialNumber: "",
    });
    const [error, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: CreateTerminalType) => {
        setLoading(true);
        try {
            const res = await CreateTerminalApi(data);
            SuccessToast(res.message);
            toggleModal();
        } catch (err: any) {
            ErrorToastWString(err);
        }
        setLoading(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            terminalId: "required",
            serialNumber: "required",
        };
        const messages = {
            "terminalId.required": "Terminal ID is required",
            "serialNumber.required": "Serial Number is required",
        };

        validateAll(formData, rules, messages)
            .then(() => {
                setErrors({});
                processCreate(formData);
            })
            .catch((errs: any) => {
                const formattedErrors = {} as any;
                errs.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    return (
        <ModalBox open toggleModal={toggleModal}>
            <div className="grid md:grid-cols-2 py-14">
                <div className="w-full">
                    <div>
                        <h3 className="text-xl font-semibold">Terminals</h3>
                        <p className="text-sm">
                            Manage all terminals and it&apos;s assigned admin
                            user{" "}
                        </p>
                    </div>
                    <form className="my-12 space-y-6">
                        <div>
                            <FloatingLabelInput
                                onChange={handleInputChange}
                                value={formData.terminalId}
                                name="terminalId"
                                label="Terminal ID"
                                error={error.numberOfDevices}
                            />
                        </div>
                        <div>
                            <FloatingLabelInput
                                onChange={handleInputChange}
                                value={formData.serialNumber}
                                name="serialNumber"
                                label="Serial Number"
                                error={error.numberOfDevices}
                            />
                        </div>
                        <CustomButton
                            buttonStyle="bg-[#009FF5] text-white text-extrabold rounded !px-6 !py-4 w-full font-semibold"
                            onClick={handleSubmit}
                        >
                            {loading ? <Loading size="xs" /> : "Send Request"}
                        </CustomButton>
                    </form>
                </div>
                <div className="items-center justify-center hidden md:flex">
                    <div className="bg-[#F4F4F4] flex items-center justify-center px-12 py-20 rounded-lg">
                        <div className="relative w-40 h-40">
                            <img
                                src={PosMachine}
                                alt="pop_machine"
                                className="absolute w-full h-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalBox>
    );
};
export default CreateTerminal;
