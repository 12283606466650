import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./Routes";

const KickOff = () => (
    <Router>
        <ToastContainer />
        <AppRoutes />
    </Router>
);

export default KickOff;
