import { validateAll } from "indicative/validator";
import React, { useEffect, useId, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "rsuite";
import { SuccessToast } from "../../../components/blocks/Alert";
import {
    CustomButton,
    DrawerButton,
} from "../../../components/elements/Button";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    createCommunityApi,
    deleteCommunityApi,
    getCommunityCategoriesApi,
} from "../../../redux/slice/communities/communities.api";
import formatDate from "../../../utils/formatDate";
import AssignFSO from "../customers/AssignFSO";
import AccountStatement from "./AccountStatement";

type Props = {
    open: boolean;
    toggleModal: () => void;
    community: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-4 text-xs border-b border-black border-dotted sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="text-right">{value}</div>
    </div>
);

const CommunityDetail = ({ open, toggleModal, community }: Props) => {
    const id = useId();
    const [formData, setFormData] = useState({
        communityName: community.communityName,
        communityCategory: community.communityCategory,
    });
    const [loading, setLoading] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [error, setError] = useState<any>("");
    const [categories, setCategories] = useState<any>([]);
    const [shouldAssign, setShouldAssign] = useState(false);
    const [shouldGenerate, setShouldGenerate] = useState("");

    const handleDelete = async () => {
        setLoading(true);
        const payload = { communityName: community.communityName };

        try {
            await deleteCommunityApi(payload);
            SuccessToast("Community Deleted Successfully");
            setLoading(false);
            toggleModal();
        } catch (err: any) {
            setLoading(false);
        }
    };

    const formatCategory = (word: any) => {
        if (word.includes("_")) {
            const newWord = word
                .split("_")
                .map((w: any) => w[0] + w.slice(1))
                .join(" ");
            return newWord;
        }
        return word;
    };

    const processUpdate = async (data: any) => {
        setLoading(true);
        const payload = {
            ...data,
            communityId: community.id,
        };
        try {
            const res = await createCommunityApi(payload);
            if (res.successful) SuccessToast("Community updated successfully");
            setLoading(false);
            setCanEdit(false);
            toggleModal();
        } catch (err: any) {
            setLoading(false);
        }
    };

    const handleUpdate = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const rules = {
            communityName: "required",
            communityCategory: "required",
        };

        const messages = {
            "communityName.required": "Community Name is required",
            "communityCategory.required": "Please select a category",
        };

        validateAll(formData, rules, messages)
            .then(() => processUpdate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAssign = () => {
        setShouldAssign(true);
    };

    const handleCancelAssign = () => {
        setShouldAssign(false);
    };

    const fetchCommunityCategories = async () => {
        try {
            const res = await getCommunityCategoriesApi();
            const list = Object.entries(res).map(e => ({
                name: e[1],
                value: e[0],
            }));
            setCategories(list);
        } catch (err: any) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommunityCategories();
    }, []);

    return (
        <>
            <DrawerPanel
                open={open}
                handleClose={toggleModal}
                title="Community Detail"
            >
                <div className="px-4 font-avenirBook">
                    <div className="py-4 mb-2 text-xs bg-white">
                        {canEdit ? (
                            <form>
                                <div className="pb-1">
                                    <FloatingLabelInput
                                        type="text"
                                        label="Community Name"
                                        onChange={e => handleInputChange(e)}
                                        name="communityName"
                                        value={formData.communityName}
                                        error={error.communityName}
                                    />
                                </div>
                                <div className="pb-1">
                                    <FloatingLabelSelectInput
                                        options={categories}
                                        label="Community Category"
                                        onChange={e => handleInputChange(e)}
                                        name="communityCategory"
                                        value={formData.communityCategory}
                                        error={error.communityCategory}
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <DrawerButton onClick={handleUpdate}>
                                        {loading ? (
                                            <Loading size="sm" />
                                        ) : (
                                            "Update Community"
                                        )}
                                    </DrawerButton>
                                    <Button
                                        onClick={() => setCanEdit(!canEdit)}
                                        className="text-red-600 bg-slate-100 hover:text-red-600"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="flex mb-6 justify-between items-center gap-4 flex-wrap">
                                    <button
                                        type="button"
                                        className="ml-auto font-semibold text-xs order-1 md:order-2 grow py-3 rounded border border-gray-200 bg-lola-black hover:bg-gray-800 text-blue-200"
                                        onClick={() =>
                                            setShouldGenerate("statement")
                                        }
                                    >
                                        Generate Account Statement
                                    </button>
                                    <div className="text-right">
                                        <CustomButton
                                            buttonStyle="bg-[#0B4665] px-3 text-white grow hover:bg-[#1270a1] py-3 rounded text-xs"
                                            onClick={handleAssign}
                                        >
                                            Assign FSO
                                        </CustomButton>
                                    </div>
                                </div>
                                <Row
                                    title="Community Name"
                                    value={community.displayCommunityName}
                                    key={id + community.displayCommunityName}
                                />
                                {Object.keys(community.communityCategory)
                                    .length !== 0 && (
                                    <Row
                                        title="Community Category"
                                        value={formatCategory(
                                            community.communityCategory
                                        )}
                                        key={id + community.communityCategory}
                                    />
                                )}
                                <Row
                                    title="Created At"
                                    value={formatDate(community.createdAt)}
                                    key={id + community.createdAt}
                                />
                                <div className="flex flex-row justify-center mt-8 space-x-8">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-xs text-white bg-blue-400 hover:bg-blue-600"
                                        onClick={() => setCanEdit(!canEdit)}
                                    >
                                        <div className="flex flex-row justify-center gap-x-2">
                                            <FaEdit /> Edit
                                        </div>
                                    </button>
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-xs font-bold text-white bg-red-500 hover:bg-red-600"
                                        onClick={handleDelete}
                                    >
                                        {loading ? (
                                            <Loading size="sm" />
                                        ) : (
                                            <div className="flex flex-row justify-center gap-x-2">
                                                <FaTrash /> Delete
                                            </div>
                                        )}
                                    </button>
                                </div>
                                {community.communityAdminsUserRecords.length >
                                0 ? (
                                    <div className="mt-14">
                                        <h2 className="font-semibold text-lg mb-2">
                                            Admin Records
                                        </h2>
                                        {community.communityAdminsUserRecords.map(
                                            (e: any) => (
                                                <Row
                                                    title={`${e.first_name} ${e.last_name}`}
                                                    value={e.phone_number}
                                                    key={id + e.userId}
                                                />
                                            )
                                        )}
                                        <div />
                                    </div>
                                ) : (
                                    "No Admin Records"
                                )}
                            </>
                        )}
                    </div>
                </div>
            </DrawerPanel>

            {shouldGenerate === "statement" && (
                <AccountStatement
                    open
                    community={community}
                    handleClose={() => setShouldGenerate("")}
                />
            )}

            {shouldAssign && (
                <AssignFSO
                    open
                    handleClose={handleCancelAssign}
                    entity={community}
                    entityCategory="community"
                />
            )}
        </>
    );
};

export default CommunityDetail;
