/* eslint-disable import/prefer-default-export */

export const handleValidationError = (errors: any) => {
    const formattedErrors = {} as any;

    errors.forEach((err: any) => {
        formattedErrors[err.field] = err.message;
    });

    return formattedErrors;
};
