import axios from "../../../axios";

export const getAllCustomerApi = async (
    pageLimit: any,
    pageNumber: any,
    query: any
) => {
    try {
        let url = `/customers?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getSmsHistoryApi = async (params: any) => {
    try {
        const url = `/customer-message-ops`;

        const res = await axios.get(url, { params });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const updateCustomerNameApi = async (payload: any) => {
    try {
        const url = "/customer-update-username-ops";

        const res = await axios.put(url, payload);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createCustomerApi = async (payload: any) => {
    try {
        const url = "/customers";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignCustomerToPosAgentApi = async (payload: any) => {
    try {
        const url = "/customers/posagents";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const generateCredentialsApi = async (payload: any) => {
    try {
        const url = "/customers/credentials";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const autoDebitCustomerApi = async (payload: any) => {
    try {
        const url = "/customers/autodebit/toggle";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const resendAccountDetailsApi = async (payload: any) => {
    try {
        const url = "/customers/accounddetailsresend";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const verifyCustomerBVNApi = async (payload: any) => {
    try {
        const url = "/verify-user/";
        const response = await axios.post(url, payload);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};
export const verifyCustomerNINApi = async (payload: any) => {
    try {
        const url = "/verify-user/";
        const response = await axios.post(url, payload);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const verifyCustomerBVNNumberApi = async (params: any) => {
    try {
        const url = "/verify-number/";
        const response = await axios.get(url, { params });
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const getCustomerByPhoneApi = async (data: any) => {
    const payload = {
        phoneNumbers: [data],
    };
    try {
        const url = `/customersbyphonenumber`;
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
