import React from "react";
import { Outlet } from "react-router";
import Sidebar from "../Sidebar/index";
import DashboardHeader from "../DashboardHeader/index";
// import ProfileInfo from "./ProfileInfo";

const MainWrapper = () => (
    <div className="flex w-full h-screen overflow-x-clip">
        <Sidebar />
        <div className="grow shrink-0 relative flex-1 overflow-x-hidden ">
            <div className="flex flex-col gap-y-2">
                <DashboardHeader />
                <div className="flex flex-col px-4 mt-6 sm:mx-6">
                    {/* <ProfileInfo /> */}
                    <div className="my-6">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default MainWrapper;
