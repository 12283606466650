import React from "react";
import { Helmet } from "react-helmet";

type HelmetProps = {
    title?: string;
    description?: string;
};

const PageHelmet = ({ title, description }: HelmetProps) => (
    <Helmet title={title}>
        <meta name="description" content={description} />
    </Helmet>
);

PageHelmet.defaultProps = {
    title: "Operations CRM | LOLA Finance",
    description: "",
};

export default PageHelmet;
