import { validateAll } from "indicative/validator";
import React, { useId, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "rsuite";
import {
    CustomErrorToast,
    InfoToast,
    SuccessToast,
} from "../../../components/blocks/Alert";
import {
    CustomButton,
    DrawerButton,
} from "../../../components/elements/Button";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    createStaffApi,
    deleteStaffApi,
} from "../../../redux/slice/staff/staff.api";
import { deAssignStaffToTeamApi } from "../../../redux/slice/teams/teams.api";
import formatWord from "../../../utils/formatWord";
import AssignStaffToTeam from "./AssignStaffToTeam";

type Props = {
    open: boolean;
    toggleModal: () => void;
    staff: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-4 text-xs border-b border-black border-dotted sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="font-bold text-right">{value}</div>
    </div>
);

const StaffDetail = ({ open, toggleModal, staff }: Props) => {
    const id = useId();
    const [formData, setFormData] = useState({
        lolaStaffUserPhoneNumber: staff.phone_number,
        operationsStaffLevel: staff.operationsStaffLevel,
        worksInCs: staff.worksInCs,
        lineManagerUserId: "",
    });
    const [canEdit, setCanEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");
    const [shouldAssign, setShouldAssign] = useState(false);
    // const [lineManagers, setLineManagers] = useState([]);

    const options = [
        { name: "Customer Support", value: "CS" },
        { name: "Frontline Sales Officer", value: "FSO" },
        { name: "State Manager", value: "STATE_MANAGER" },
        { name: "HQ Employee", value: "HQ_EMPLOYEE" },
        { name: "HQ Manager", value: "HQ_MANAGER" },
    ];

    const CSOptions = [
        { name: "Yes", value: "true" },
        { name: "No", value: "false" },
    ];

    const deassignStaff = async () => {
        setLoading(true);
        const payload = {
            lolaStaffUserId: staff.id,
            teamId: staff.teamId,
        };
        try {
            const res = await deAssignStaffToTeamApi(payload);
            if (res.successful) {
                SuccessToast(`${res.message}. Please refresh to update data.`);
                toggleModal();
            }
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleAssignStaff = () => {
        if (staff.teamName !== "-") {
            deassignStaff();
        }

        if (staff.teamName === "-") {
            setShouldAssign(true);
        }
    };

    const handleCloseAssignStaff = () => {
        setShouldAssign(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const res = await deleteStaffApi(staff.id);
            if (res.successful) SuccessToast(res.message);
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processUpdate = async (data: any) => {
        setLoading(true);
        try {
            const res = await createStaffApi(data);
            if (res.successful) SuccessToast("Staff updated successfully");
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
            setCanEdit(false);
            toggleModal();
        } catch (err: any) {
            setLoading(false);
            CustomErrorToast(err);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            lolaStaffUserPhoneNumber: "required",
            operationsStaffLevel: "required",
            worksInCs: "required",
        };

        const messages = {
            "lolaStaffUserPhoneNumber.required":
                "Staff Phone Number is required",
            "operationsStaffLevel.required": "Staff level is required",
            "worksInCs.required": "Please select an option",
        };

        validateAll(formData, rules, messages)
            .then(() => processUpdate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <>
            <DrawerPanel
                open={open}
                handleClose={toggleModal}
                title="Staff Detail"
            >
                <div className="px-4 font-avenirBook">
                    <div className="py-4 mb-2 text-xs bg-white">
                        {canEdit ? (
                            <form>
                                <div className="pb-1">
                                    <FloatingLabelInput
                                        type="tel"
                                        label="Staff Phone Number"
                                        onChange={e => handleInputChange(e)}
                                        name="lolaStaffUserPhoneNumber"
                                        value={
                                            formData.lolaStaffUserPhoneNumber
                                        }
                                        error={error.lolaStaffUserPhoneNumber}
                                    />
                                </div>
                                <div className="pb-1">
                                    <FloatingLabelSelectInput
                                        options={options}
                                        label="Operation Staff Level"
                                        onChange={handleInputChange}
                                        name="operationsStaffLevel"
                                        value={formData.operationsStaffLevel}
                                        error={error.operationsStaffLevel}
                                    />
                                </div>
                                <div className="pb-1">
                                    <FloatingLabelSelectInput
                                        options={CSOptions}
                                        label="Work's in Customer Support"
                                        onChange={handleInputChange}
                                        name="worksInCs"
                                        value={formData.worksInCs}
                                        error={error.worksInCs}
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <DrawerButton onClick={handleSubmit}>
                                        {loading ? (
                                            <Loading size="sm" />
                                        ) : (
                                            "Update Staff"
                                        )}
                                    </DrawerButton>
                                    <Button
                                        onClick={() => setCanEdit(!canEdit)}
                                        className="text-red-600 bg-slate-100 hover:text-red-600"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="flex justify-end pb-4">
                                    <div className="">
                                        <CustomButton
                                            buttonStyle="bg-[#0B4665] text-white px-4 hover:bg-[#1270a1] py-2 text-xs"
                                            onClick={() => handleAssignStaff()}
                                        >
                                            {loading ? (
                                                <Loading size="xs" />
                                            ) : staff.teamName !== "-" ? (
                                                "Deassign Team"
                                            ) : (
                                                "Assign Team"
                                            )}
                                        </CustomButton>
                                    </div>
                                </div>
                                {Object.entries(staff)
                                    .filter(
                                        ([key, value]) =>
                                            key !== "id" && key !== "teamId"
                                    )
                                    .map(d => (
                                        <Row
                                            title={formatWord(d[0])}
                                            value={d[1]}
                                            key={id + d[0]}
                                        />
                                    ))}
                                <div className="flex flex-row justify-between mt-10 space-x-8">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm text-white bg-blue-400 hover:bg-blue-600"
                                        onClick={() => setCanEdit(!canEdit)}
                                    >
                                        <div className="flex flex-row justify-center gap-x-2">
                                            <FaEdit /> Edit
                                        </div>
                                    </button>
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-700"
                                        onClick={handleDelete}
                                    >
                                        {loading ? (
                                            <Loading size="sm" />
                                        ) : (
                                            <div className="flex flex-row justify-center gap-x-2">
                                                <FaTrash /> Delete
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </DrawerPanel>
            {shouldAssign && (
                <AssignStaffToTeam
                    open
                    handleClose={handleCloseAssignStaff}
                    userId={staff.id}
                />
            )}
        </>
    );
};

export default StaffDetail;
