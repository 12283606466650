import React from "react";
import LolaImgMin from "../../../assets/images/logo/lola-min.png";
import Loading from "../../../components/elements/Loading/Loading";
import numberFormat from "../../../utils/numberFormat";

type Props = {
    loading: boolean;
    title: string;
    value: number;
};

const ReportCards = ({ loading, title, value }: Props) => (
    <div className="flex flex-col w-full mb-8 sm:flex-row justify-items-start gap-y-3 sm:gap-0 sm:w-auto shrink">
        <div
            className="relative border-0 rounded-lg p-5 bg-[#013653] w-full sm:w-56 h-28 sm:mr-5"
            style={{
                backgroundImage: `url(${LolaImgMin})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "102% 104%",
                backgroundSize: "60px",
            }}
        >
            <div className="text-[#C3D1FB] text-xs font-avenirBook">
                {title}
            </div>
            <div className="flex items-center mt-2 gap-x-4">
                <div className="flex items-center text-lg tracking-wide text-white font-gilroyBold">
                    {loading ? <Loading size="sm" /> : `${numberFormat(value)}`}
                </div>
            </div>
        </div>
    </div>
);

export default ReportCards;
