import axios from "../../../axios";
import type {
    CreateTerminalType,
    GetPosTerminalApiQueryParams,
    ReAssignTerminalPayload,
    UpdateATerminalRequestType,
} from "../../../types/api.types";

export const GetAllRequestedTerminalsApi = async (
    queryParams?: GetPosTerminalApiQueryParams
) => {
    try {
        const url = "/terminal/request/all";

        const res = await axios.get(url, {
            params: queryParams,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetAllTerminalsApi = async (
    queryParams?: GetPosTerminalApiQueryParams
) => {
    try {
        const url = "/terminal";

        const res = await axios.get(url, {
            params: queryParams,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const CreateTerminalApi = async (payload: CreateTerminalType) => {
    try {
        const url = "/terminal/create";
        const res = await axios.post(url, payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const UpdateATerminalRequest = async (
    payload: UpdateATerminalRequestType
) => {
    const { status, requestId } = payload;

    const url = `terminal/request/${requestId}?status=${status}`;
    try {
        const res = await axios.put(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetSingleTerminalRequestAPI = async (terminalID: string) => {
    const url = `/terminal/request/${terminalID}`;

    try {
        const res = await axios.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetTerminalDetailsApi = async (terminalID: string) => {
    const url = `/terminal/${terminalID}`;

    try {
        const res = await axios.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const ReAssignTerminalApi = async (args: ReAssignTerminalPayload) => {
    const { assign, terminalId } = args;
    let payload = {
        terminalId,
    };

    let url = "/terminal/map?assign=false";

    if (assign) {
        const { type, assign: _, ...rest } = args;
        payload = rest;
        url = `/terminal/map?type=${type}&assign=true`;
    }
    try {
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
