import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useParams } from "react-router";
import PageHelmet from "../../../components/blocks/PageHelmet";
import numberFormat from "../../../utils/numberFormat";
import formatDate from "../../../utils/formatDate";
import SearchInput from "../../../components/elements/Inputs/SearchInput";
import { ErrorToast } from "../../../components/blocks/Alert";
import LBreadCrumb from "../../../components/elements/BreadCrumb/indext";
import Loading from "../../../components/elements/Loading/Loading";
import { MainButton } from "../../../components/elements/Button";
import TerminalDetails from "./TerminalDetails";

type DataRow = {
    id: number;
    description: string;
    amount: number;
    transactionType: string;
    dateOfTransaction: string;
    status: string;
};

const dummyHeader = ["amount processed", "base payout", "payout limit"];

const TerminalTransactions = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        fromData: "",
        toDate: "",
        search: "",
    });
    const [terminalTransactions, setTerminalTransactions] = useState<DataRow[]>(
        []
    );

    const [showTerminalDetails, setShowTerminalDetails] = useState(false);

    const BreadCrumbData = [
        {
            id: 0,
            link: "/pos-terminals",
            title: "Terminals",
        },
        {
            id: 1,
            isActive: true,
            title: id ?? "N/A",
        },
    ];

    const fetchTerminalTransaction = async () => {
        try {
            setLoading(true);
            const res = {
                data: { data: [] },
            };

            const filteredArray: any[] = [];
            res.data?.data.forEach((item: any) => {
                if (item && item.transaction) {
                    filteredArray.push(item?.transaction);
                }
            });

            setTerminalTransactions(filteredArray);
        } catch (err) {
            ErrorToast(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTerminalTransaction();
    }, []);

    const columns: TableColumn<DataRow>[] = useMemo(
        () => [
            {
                name: "Description",
                selector: (row: any) => row.description,
            },
            {
                name: "Amount",
                sortable: true,
                selector: (row: any) => `₦ ${numberFormat(row.amount)}`,
            },
            {
                name: "Transaction Type",
                selector: (row: any) => row.type,
            },
            {
                name: "Transaction Date",
                selector: (row: any) => formatDate(row.dateUpdated),
            },
            {
                name: "Status",
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: (row: any) => (
                    <div
                        className={`${
                            row.status === "successful" && "text-green-500"
                        } ${row.status === "pending" && "text-teal-500"}`}
                    >
                        {row.status}
                    </div>
                ),
            },
        ],
        []
    );

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        setLoading(false);
    };

    return (
        <div>
            <PageHelmet title="" />
            <div className="container mx-auto my-10">
                <LBreadCrumb data={BreadCrumbData} />
                <div>
                    <h3 className="text-xl font-semibold">{id} POS Terminal</h3>
                    <p className="text-sm">
                        Manage terminal transactions and volume proceeded each
                        month.{" "}
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 my-6 gap-2 sm:gap-5 max-w-[750px]">
                    {dummyHeader.map((n, idx) => (
                        <div
                            className={`h-[140px] flex flex-col items-center justify-center text-center sm:text-left rounded-xl bg-[#F7F4F4] curver--border-bottom${
                                idx === 2 ? " sm:col-span-1 col-span-2" : ""
                            }`}
                        >
                            <div className="space-y-2 font-semibold">
                                <p className="capitalize">{n}</p>
                                <h4 className="text-2xl">N/A </h4>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="my-10">
                    <div className="flex gap-4 flex-wrap items-start justify-between">
                        <div>
                            <h4 className="text-xl font-semibold">
                                Transaction History
                            </h4>
                            <div className="w-[400px]">
                                <SearchInput
                                    value={formData.search}
                                    onChange={handleInputChange}
                                    name="search"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                        <MainButton
                            onClick={() => setShowTerminalDetails(true)}
                        >
                            View Terminal Details
                        </MainButton>
                    </div>
                    <div>
                        <div className="my-8 capitalize">
                            <DataTable
                                columns={columns}
                                data={terminalTransactions}
                                highlightOnHover
                                responsive
                                subHeader
                                subHeaderWrap
                                progressPending={loading}
                                progressComponent={<Loading />}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>

            {showTerminalDetails && (
                <TerminalDetails
                    open
                    toggleModal={() => setShowTerminalDetails(false)}
                    terminalID={String(id ?? "N/A")}
                />
            )}
        </div>
    );
};

export default TerminalTransactions;
