import { validateAll } from "indicative/validator";
import React, { memo, useState } from "react";
import { Modal } from "rsuite";
import { InfoToast, SuccessToast } from "../../../components/elements/Alerts";
import { DrawerButton } from "../../../components/elements/Button";
import TextAreaGroup from "../../../components/elements/Inputs/TextAreaGroup/TextAreaGroup";
import Loading from "../../../components/elements/Loading/Loading";
import { communitySuccessConversationsApi } from "../../../redux/slice/workspaces/workspaces.api";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput/index";

type Props = {
    open: boolean;
    handleClose: (isRecorde?: any) => void;
    callStartedAt: Date;
    userData: any;
};

const RecordFeedback = ({
    open,
    handleClose,
    callStartedAt,
    userData,
}: Props) => {
    const [formData, setFormData] = useState({
        feedback: "",
        customerNeedsToBeFollowedUpWithLater: "yes",
        followUpDateTime: "",
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>("");
    const [callEndedAt, setCallEndedAt] = useState<Date>(new Date());

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processRecord = async (data: any) => {
        const payload = {
            lolaCustomerUserId: userData.lolaCustomerUserId,
            lolaStaffUserId: userData.lolaStaffUserId,
            callStartedAt,
            callEndedAt,
            noteContent: data.feedback,
            customerNeedsToBeFollowedUpWithLater:
                data.customerNeedsToBeFollowedUpWithLater === "yes",
            followUpDateTime: data.followUpDateTime,
        };
        setLoading(true);
        try {
            const res = await communitySuccessConversationsApi(payload);
            if (res.successful) {
                SuccessToast(res.message);
                handleClose(true);
            }
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const endedCallAt = new Date();
        setCallEndedAt(endedCallAt);

        const rules = {
            followUpDateTime: "required",
            feedback: "required",
        };

        const messages = {
            "followUpDateTime.required": "Follow up date and time is required",
            "feedback.required": "Feedback is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processRecord(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdrop="static"
            // backdropClassName="flex justify-center items-center"
            keyboard={false}
        >
            <div>
                <FloatingLabelInput
                    type="datetime-local"
                    label="Follow up Date"
                    name="followUpDateTime"
                    value={formData.followUpDateTime}
                    min={new Date().toJSON().slice(0, 16)}
                    onChange={e => handleInputChange(e)}
                    error={error.followUpDateTime}
                />
            </div>
            <TextAreaGroup
                name="feedback"
                value={formData.feedback}
                placeholder="Notes about the call with customer ... "
                onChange={e => handleInputChange(e)}
                error={error.feedback}
                label="Call Feedback"
            />

            <div className="flex flex-col justify-center">
                <DrawerButton onClick={handleSubmit}>
                    {loading ? <Loading size="sm" /> : "Record Feedback"}
                </DrawerButton>
            </div>
        </Modal>
    );
};

export default memo(RecordFeedback);
